<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div class="mb-6">
      <h2 class="font-semibold text-blue-">Payment Logs</h2>
    </div>
    <div v-if="true" class="flex flex-col">
      <div
        class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
      >
        <div class="">
          <div class="flex time-filter">
            <span>From: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startdatetime"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startTime"
            />
          </div>
          <div class="grid time-filter mt-2">
            <span>To: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.enddatetime"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.endTime"
            />
          </div>
          <!-- <div class="flex items-center  mt-2">
            <span>Settlement Filter: </span>
            <multiselect
              :taggable="false"
              v-model="filterBy"
              :options="['instant', 'sameday']"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Filter"
            >
            </multiselect>
          </div> -->
          <div class="flex items-center  mt-2">
            <span>Settlement Filter: </span>
            <div class="flex ml-4 items-center ">
              <input
                v-model="filterBy"
                type="checkbox"
                value="instant"
                name=""
                id=""
              />
              <p class="ml-3">Instant</p>
            </div>
            <div class="flex ml-4 items-center ">
              <input
                v-model="filterBy"
                type="checkbox"
                value="sameday"
                name=""
                id=""
              />
              <p class="ml-3">Same Day</p>
            </div>
          </div>
          <div class="flex items-center  mt-2">
            <span>Wallet Filter: </span>
            <div class="flex ml-4 items-center ">
              <input
                v-model="walletFilter"
                type="checkbox"
                value="wallet"
                name=""
                id=""
              />
              <p class="ml-3">Wallet</p>
            </div>
          </div>
        </div>
        <!-- component -->
        <div class="flex">
          <button
            @click="fetchRecords"
            class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
          >
            Get Records
          </button>
          <button
            @click="generateCSV"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
          >
            Download
          </button>
        </div>
      </div>
      <div class="" v-if="displayedData">
        <div
          @scroll="checkScroll($event)"
          class="overflow-scroll pb-6"
          :class="displayedData.length > 50 ? 'h-screen' : ''"
          v-if="displayedData.length > 0"
        >
          <table class="shadow-lg bg-white w-full">
            <thead>
              <th
                class="text-l p-3 text-left"
                colspan="55"
                style="background: #DEE6F2;"
              >
                Merchant Information
              </th>
              <tr>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Index
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  ID
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Business ID
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Terminal ID
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  RRN
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  STAN
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Settlement Type
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Credit Wallet Status
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Is Fraud?
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Business Email
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Business Name
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Pricing Group
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Business Phone
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Payment Channel
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Payment Method
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Created At
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Updated At
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Is Payment Received
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Is Payoutt Disbursed
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Is Logged To Payout
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Date Payment Received
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Date Payout Disbursed
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Txn Type
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Txn Type ID
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Acquirer Bank
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Store ID
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Store Name
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Sale Agent
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Customer Name
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Payment Channel
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Card Type
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Card Number
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Payment Receipt
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Receipt URL
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Amount Paid
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Bank Name
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Bank Code
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Account Name
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Account No.
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Payment Date
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Payment Method
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Amount Paid
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Txn Fee
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Loan Fee
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Net Fee Due Merchant
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Sale Mode
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Offline Sale ID
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Offline Date
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Payment Ref.
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Recipient Detail Payment Ref. ID
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Disbursement Detail Payout Date
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Disbursement Detail Payout Method
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Disbursement Detail Payout Agent ID
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Disbursement Detail Payout Agent Name
                </th>
                <th
                  class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
                >
                  Customer Phone
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(log, i) in displayedData"
                :key="i"
                class="hover:bg-gray-300"
              >
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ i + 1 }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log._id }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.business_id }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.terminal_details.terminal_id }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.pos_payment_details.rrn }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.pos_payment_details.stan }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs lowercase"
                >
                  {{ log.settlement_type || "N/A" }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs lowercase"
                >
                  {{ log.recipient_details.credit_wallet_status }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs lowercase"
                >
                  {{ log.is_fraud }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs lowercase"
                >
                  {{ log.business_email }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs uppercase"
                >
                  {{ log.business_name }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.pricing_group }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.business_phone }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.payment_channel }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.payment_method }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ moment(log.created_at).format("DD MMM YYYY - h:mm:ss a") }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ moment(log.updated_at).format("DD MMM YYYY - h:mm:ss a") }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.is_payment_received_confirmed }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.is_payout_disbursed_confirmed }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.is_logged_to_payout_table }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{
                    moment(log.date_payment_received).format(
                      "DD MMM YYYY - h:mm:ss a"
                    )
                  }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{
                    moment(log.date_payout_disbursed).format(
                      "DD MMM YYYY - h:mm:ss a"
                    )
                  }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.transaction_type }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.transaction_type_id }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.acquirer_bank }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.store_id }}
                </td>

                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.store_name }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.sale_agent }}
                </td>

                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.customer_details.customer_name }}
                </td>

                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.payment_details.channel }}
                </td>

                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.payment_details.card_type }}
                </td>

                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.payment_details.card_number }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.recipient_details.payment_details.payment_receipt }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs text-blue-500"
                >
                  <p
                    v-if="
                      log.recipient_details.payment_details.receipt_url === 'na'
                    "
                  >
                    {{ log.recipient_details.payment_details.receipt_url }}
                  </p>
                  <a
                    v-else
                    target="_blank"
                    :href="log.recipient_details.payment_details.receipt_url"
                    >{{ log.recipient_details.payment_details.receipt_url }}</a
                  >
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{
                    log.recipient_details.payment_details.amount_paid
                      | formatPrice(false)
                  }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.bank_payment_details.bank_name }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.bank_payment_details.bank_code }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.bank_payment_details.account_name }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{
                    log.recipient_details.bank_payment_details.account_number
                  }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{
                    moment(log.recipient_details.payment_date).format(
                      "DD MMM YYYY"
                    )
                  }}
                </td>

                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.payment_method }}
                </td>

                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.amount_paid | formatPrice(false) }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{
                    log.recipient_details.transaction_fee | formatPrice(false)
                  }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.loan_fee | formatPrice(false) }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{
                    log.recipient_details.net_fee_due_merchant
                      | formatPrice(false)
                  }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.sale_mode }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.offline_sale_id }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{
                    moment(log.recipient_details.offline_date).format(
                      "DD MMM YYYY"
                    )
                  }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.payment_reference }}
                </td>
                <td
                  class="border px-8 py-3 whitespace-nowrap text-xs capitalize"
                >
                  {{ log.recipient_details.payment_reference_id }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{
                    moment(log.disbursement_details.payout_date).format(
                      "DD MMM YYYY"
                    )
                  }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.disbursement_details.payout_method }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.disbursement_details.payout_agent_id }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.disbursement_details.payout_agent_name }}
                </td>
                <td class="border px-8 py-3 whitespace-nowrap text-xs">
                  {{ log.customer.phone || "N/A" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex justify-center items-center h-52" v-else>
          <p>No data available.</p>
        </div>
        <div class="mt-3" v-if="displayedData.length > 50">
          <button
            @click="prevPage(currentPage)"
            :disabled="Disabled"
            class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    
                    focus:shadow-outline
                    focus:outline-none
                    
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
            :class="
              Disabled
                ? 'bg-blue-100 text-black text-xs'
                : 'bg-blue-900 text-white text-xs'
            "
          >
            &laquo; Prev
          </button>

          <button
            @click="nextPage(currentPage)"
            class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
          >
            Next &raquo;
          </button>
        </div>
        <div
          v-if="alertDiv"
          class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
        >
          <div
            class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
              />
            </svg>
            Please check your email in 5 mins for the download link.
            <br />Please note that it will take longer to generate a download
            link for larger date ranges.
            <button
              @click="alertDiv = false"
              class="
                    float-right
                    uppercase
                    shadow
                    mx-auto
                    hover:bg-red-600
                    bg-red-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-2
                    rounded
                  "
              style="width:inherit"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { json2excel } from "js2excel";
import moment from "moment";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      moment,
      isLoading: true,
      paymentLogs: [],
      dateData: null,
      tim: new Date().getTime(),
      filterBy: [],
      walletFilter: [],
      divComponent: null,
      currentPage: 1,
      isDisabled: false,
      alertDiv: false,
    };
  },
  created() {
    const d = new Date();

    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };

    this.fetchRecords();
  },
  components: {
    Multiselect,
  },

  computed: {
    Disabled() {
      if (this.currentPage === 1) return (this.isDisabled = true);
    },
    displayedData() {
      let logs = this.paymentLogs;
      let otherLog = this.paymentLogs;

      if (this.filterBy.length === 0 && this.walletFilter.length === 0) {
        logs;
      }

      if (this.walletFilter.includes("wallet")) {
        logs.filter((log) => log.recipient_details.credit_wallet_status);
      }

      if (this.filterBy.length === 1) {
        logs.filter(
          (log) =>
            log.settlement_type.toLowerCase() === this.filterBy[0].toLowerCase()
        );
      }
      if (this.filterBy.length === 2) {
        logs.filter((log) => log.settlement_type);
      }

      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (!bottomOfWindow) {
          logs.slice(0, 100);
        } else {
          logs.push(...otherLog.slice(logs.length, logs.length + 20));
        }
      };
      return logs;
    },
  },
  methods: {
    checkScroll(e) {
      this.divComponent = e.target;
      // const shownCustomersSofar = this.displayedData;
    },
    async fetchRecords() {
      this.isLoading = true;

      let res = await this.$store.dispatch(
        "FETCH_PAYMENTS_LOGS",
        this.dateData
      );
      // const res = this.$store.getters["GET_PAYMENT_LOGS_RES"];

      if (res.status) {
        const data = res.data ? res.data.items || [] : [];
        this.paymentLogs = data.sort((a, b) =>
          a.business_name.trim().toLowerCase() >
          b.business_name.trim().toLowerCase()
            ? 1
            : -1
        );
      } else {
        alert(
          res.error ||
            res.message ||
            "Error: Please check network or contact admin."
        );
      }
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let curr_page = this.currentPage + 1;
      let payload = {
        date: this.dateData,
        page: curr_page,
      };
      console.log(payload);
      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_PAYMENT_LOGS",
          payload
        );
        if (res.status) {
          this.currentPage++;
          const data = res.data ? res.data.items || [] : [];
          this.paymentLogs = data.sort((a, b) =>
            a.business_name.trim().toLowerCase() >
            b.business_name.trim().toLowerCase()
              ? 1
              : -1
          );
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
        this.isLoading = false;
      } catch (error) {
        alert(error);
      }
    },
    async prevPage() {
      this.isLoading = true;

      let payload = {
        date: this.dateData,
        page: this.currentPage - 1,
      };

      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_PAYMENT_LOGS",
        payload
      );
      // const res = this.$store.getters["GET_PAYMENT_LOGS_RES"];

      if (res.status) {
        this.currentPage--;
        const data = res.data ? res.data.items || [] : [];
        this.paymentLogs = data.sort((a, b) =>
          a.business_name.trim().toLowerCase() >
          b.business_name.trim().toLowerCase()
            ? 1
            : -1
        );
      } else {
        alert(
          res.error ||
            res.message ||
            "Error: Please check network or contact admin."
        );
      }
      this.isLoading = false;
    },
    async generateCSV() {
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch(
          "DOWNLOAD_PAYMENT_LOG_CSV",
          this.dateData
        );

        if (response.status) {
          // window.open(response.data, "_blank");
          this.alertDiv = true;
          // this.alertDivMessage = `${response.message}
          //  <br>\n Please note that it will take longer to generate a download link for larger date ranges`;

          this.isLoading = false;
        } else {
          this.isLoading = false;
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
    mapFetchData() {
      return this.paymentLogs.map((log) => {
        return {
          ID: log._id,
          "Business ID": log.business_id,
          "Terminal ID": log.recipient_details.terminal_details.terminal_id,
          "Business Email": log.business_email,
          "Business Name": log.business_name,
          "Pricing Group": log.pricing_group,
          "Business Phone": log.business_phone,
          "Payment Channel": log.payment_channel,
          "Payment Method": log.recipient_details.payment_method
            .split("_")
            .join(" "),
          "Created At": this.moment(log.created_at).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Updated At": this.moment(log.updated_at).format(
            "DD MMM YYYY - h:mm:ss a"
          ),
          "Is Payment Received": log.is_payment_received_confirmed,
          "Is Payoutt Disbursed": log.is_payout_disbursed_confirmed,
          "Is Logged To Payout": log.is_logged_to_payout_table,
          "Date Payment Received": this.moment(
            log.date_payment_received
          ).format("DD MMM YYYY - h:mm:ss a"),
          "Date Payout Disbursed": this.moment(
            log.date_payout_disbursed
          ).format("DD MMM YYYY - h:mm:ss a"),
          "Recipient Detail Txn Type": log.recipient_details.transaction_type,
          "Recipient Detail Txn ype ID":
            log.recipient_details.transaction_type_id,
          "Recipient Detail Acquirer Bank": log.recipient_details.acquirer_bank,
          "Recipient Detail Store ID": log.recipient_details.store_id,
          "Recipient Detail Store Name": log.recipient_details.store_name,
          "Recipient Detail Sale Agent": log.recipient_details.sale_agent,
          "Recipient Detail Customer Name":
            log.recipient_details.customer_details.customer_name,
          "Recipient Detail Payment Channel":
            log.recipient_details.payment_details.channel,
          "Recipient Detail Card Type":
            log.recipient_details.payment_details.card_type,
          "Recipient Detail Card Number":
            log.recipient_details.payment_details.card_number,
          "Recipient Detail Payment Receipt":
            log.recipient_details.payment_details.payment_receipt,
          "Recipient Detail Receipt URL":
            log.recipient_details.payment_details.receipt_url,
          "Recipient Detail Amount Paid":
            log.recipient_details.payment_details.amount_paid,
          "Recipient Detail Bank Name":
            log.recipient_details.bank_payment_details.bank_name,
          "Recipient Detail Bank Code":
            log.recipient_details.bank_payment_details.bank_code,
          "Recipient Detail Account Name":
            log.recipient_details.bank_payment_details.account_name,
          "Recipient Detail Account No.":
            log.recipient_details.bank_payment_details.account_number,
          "Recipient Detail Payment Date": this.moment(
            log.recipient_details.payment_date
          ).format("DD MMM YYYY - h:mm:ss a"),
          "Recipient Detail Payment Method": log.recipient_details.payment_method
            .split("_")
            .join(" "),
          "Recipient Detail Amount Paid": log.recipient_details.amount_paid,
          "Recipient Detail Txn Fee": log.recipient_details.transaction_fee,
          "Recipient Detail Loan Fee": log.recipient_details.loan_fee,
          "Recipient Detail Net ee Due Merchant":
            log.recipient_details.net_fee_due_merchant,
          "Recipient Detail Sale Mode": log.recipient_details.sale_mode,
          "Recipient Detail Offline ale ID":
            log.recipient_details.offline_sale_id,
          "Recipient Detail Offline Date": this.moment(
            log.recipient_details.offline_date
          ).format("DD MMM YYYY - h:mm:ss a"),
          "Payment Ref.": log.recipient_details.payment_reference,
          "Recipient Detail Payment Ref. ID":
            log.recipient_details.payment_reference_id,
          "Disbursement Detail Payout Date": this.moment(
            log.disbursement_details.payout_date
          ).format("DD MMM YYYY - h:mm:ss a"),
          "Disbursement Detail Payout Method":
            log.disbursement_details.payout_method,
          "Disbursement Detail Payout Agent ID":
            log.disbursement_details.payout_agent_id,
          "Disbursement Detail Payout Agent Name":
            log.disbursement_details.payout_agent_name,
          STAN: log.recipient_details.pos_payment_details.stan,
          RRN: log.recipient_details.pos_payment_details.rrn,
          "Settlemet Type": log.settlement_type,
          "Credit Wallet Status": log.recipient_details.credit_wallet_status,
          "Is Fraud?": log.is_fraud,
        };
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.time-filter {
  grid-template-columns: 50px 1fr 110px;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
